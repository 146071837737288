<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven/hotflow_three">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow3.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HOTFLOW THREE</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  A NEW ERSA FOR REFLOW
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven/exos10_20">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos10_26.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">EXOS 10/26</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Inline reflow soldering under vacuum
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven/hotflow4">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow4.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HOTFLOW 4</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Reflow soldering with excellent performance and best energy balance
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven/hotflow3">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow33.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title"> HOTFLOW 3</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Reflow system for maximum flexibility
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven/hotflow_3e">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow3e.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HOTFLOW 3E</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Energy-efficient reflow system for maximum throughput
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
       
      </div>



      
    
      
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>